import React from "react";
import {injectIntl} from "gatsby-plugin-react-intl";
import { Container } from 'react-bootstrap';
import FooterData from "../content/footer.json";
import StatusPage from "../components/misc/status-page";
import CookieManager from "./misc/cookie-manager";


function Footer({ intl, siteTitle, theme }) {
    return (
        <>
            <footer className={`bg-` + theme}>
                <Container>
                    <StatusPage />
                    <div className={`copyright`}>
                        © 2013 - {new Date().getFullYear()} {intl.formatMessage({id : "components.footer.copyright"})}
                        {FooterData[intl.locale].copyright.map((item, i) => {
                            return(
                                <a key={i} className={`copyright-link`} href={item.url} target={`_blank`} rel={`noopener`}>
                                    {item.label}
                                </a>
                            )
                        })}
                    </div>
                </Container>
            </footer>
            <CookieManager />
        </>
    )
}

export default injectIntl(Footer)
