import React from "react";
import {injectIntl} from "gatsby-plugin-react-intl";
import { launch, onReady } from 'notificare-web/core';
import {
    onNotificationOpened,
    onNotificationActionOpened
} from 'notificare-web/push';
import { presentNotification, presentAction } from 'notificare-web/push-ui';
import {
    onMessagePresented,
    onMessageFinishedPresenting,
    onMessageFailedToPresent,
    onActionExecuted,
    onActionFailedToExecute,
} from 'notificare-web/in-app-messaging';
class NotificarePushManager extends React.Component {
    async componentDidMount(){
        document.documentElement.setAttribute('data-notificare-theme', 'light');
        this.initNotificare();
    }
    initNotificare = async () => {
        await launch();

        onReady(async (app) => {
            //console.log(app);
        });
        onNotificationOpened((notification) => {
            presentNotification(notification);
        });

        onNotificationActionOpened((notification, action) => {
            presentAction(notification, action);
        });

        onMessagePresented((message) => {
            //console.log(message);
        });

        onMessageFinishedPresenting((message) => {
            //console.log(message);
        });

        onMessageFailedToPresent((message) => {
            //console.log(message);
        });

        onActionExecuted((message) => {
            //console.log(message);
        });

        onActionFailedToExecute((message) => {
            //console.log(message);
        });
    }

    render() {
        return null;
    }
}

export default injectIntl(NotificarePushManager);
